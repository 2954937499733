<script>
import SearchResultCard from '@/components/discovery/SearchResultCard'
import EmptyList from '@/components/general/EmptyList.vue'

export default {
  name: 'DiscoveryCoursesDefault',
  components: {
    SearchResultCard,
    EmptyList
  },
  data () {
    return {
      filteredList: null
    }
  },
  methods: {
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    },
    showModalContentDetails (params) {
      this.$emit('showModalContentDetails', params)
    },
    formatFilteredCourses (filteredCourses) {
      return filteredCourses.map(filteredCourse => ({
        type: 'course',
        id: filteredCourse.id,
        title: filteredCourse.name,
        provider: {
          name: filteredCourse.provider,
          logo: filteredCourse.logo
        },
        modality: filteredCourse.modality,
        price: filteredCourse.price,
        workload: filteredCourse.workload.toString(),
        isFavorite: filteredCourse.isFavorite,
        courseUrl: filteredCourse.url
      }))
    }
  },
  created () {
    this.$store.dispatch('attemptGetCourseSuggestions', { aggregator: this.getCompanyDomainAndWorkspace() })
      .then(res => {
        this.filteredList = res
      })
  }
}
</script>
<template>
  <div class="discovery-default--container">
    <div v-if="filteredList && filteredList.length">
      <search-result-card v-for="(filtedItem, i) in filteredList" :key="i" :items="filtedItem"
        type="courses" />
    </div>
    <div class="margin-empty" v-else-if="filteredList && filteredList.length === 0">
      <empty-list class="margin-empty" :title="$t('empty.state.title:empty.title')"
        :image="'/assets/images/empty-box.png'"></empty-list>
    </div>
  </div>
</template>
